@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}
    
    .h-big {
        font-size: 22px;
        line-height: normal;
        
        @screen m {
            font-size: 35px;
        }
        
        @screen l {
            font-size: clamp(48px, 4.167vw, 80px);
        }
    }

    .h-medium {
        font-size: 22px;
        line-height: normal;
        
        @screen m {
            font-size: 28px;
        }
        
        @screen l {
            font-size: clamp(28px, 2.5vw, 48px);
        }
    }
    
    .h-medium-plus {
        font-size: 22px;
        line-height: normal;
        
        @screen m {
            font-size: 29px;
        }
        
        @screen l {
            font-size: clamp(29px, 3.5vw, 69px);
        }
    }
    
    .h-small {
        font-size: 18px;
        line-height: normal;
        
        @screen m {
            font-size: 24px;
        }
        
        @screen l {
            font-size: clamp(24px, 1.667vw, 32px);
        }
    }

    .h-huge {
        font-size: 40px;
        line-height: normal;
        
        @screen m {
            font-size: 70px;
        }
        
        @screen l {
            font-size: clamp(70px, 8.333vw, 160px);
            line-height: 99%;
        }
    }
    
    .homepage-menu {
        font-size: 18px;
        line-height: normal;
        
        @screen m {
            font-size: 24px;
        }
        @screen l {
            font-size: 28px;
        }
    }
    
    .h1 {
        @apply font-gooper;
        
        font-size: 32px;
        
        @screen l {
            font-size: 48px;
        }
    }
    
    .article-body {
        @apply font-saans;
        
        font-size: 16px;
        line-height: 1.6;
        
        @screen m {
            font-size: 18px;
        }
        @screen l {
            font-size: 20px;
        }
    }

}
