.wysiwyg {
    p {
        margin-bottom: 1.6em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong, b {
        font-weight: 700;
    }

    a {
        text-decoration: underline;
    }

    em, i {
        font-style: italic;
    }

    h2 {
        @apply font-gooper font-medium;
        font-size: 1.2em;
        line-height: 1.2;

        margin-bottom: 0.85em;
        margin-top: 2.5em;

        &:first-child {
            margin-top: 0;
        }
    }

    ol {
        counter-reset: item;
    }

    li {
        display: list-item;
        position: relative;
        padding: 0 0 10px 10px;
        margin-left: 40px;
    }
    
    table, th, td {
        background-color: transparent !important;
    }
    
    figure.table {
        overflow: auto;
        display: block !important;
    }
    
    table {
        min-width: 800px;
        font-size: 0.75em;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    
    td, th {
        vertical-align: top !important;
        text-align: left !important;
        padding: 5px !important;
        width: auto !important;
        height: auto !important;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

}
